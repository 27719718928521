export const adv = [
    {
        title: 'Профессиональная поддержка на каждом шаге',
        subtitle: 'Мы всегда готовы помочь вам с выбором и использованием продукта. Наши эксперты обеспечат простой и комфортный опыт, предлагая лучшее обслуживание и постоянную поддержку.'
    },
    {
        title: 'Лучшие цены и гарантия',
        subtitle: 'Как официальные реселлеры OneKey, мы предлагаем оригинальные устройства по доступным ценам с официальной гарантией. Вы всегда можете быть уверены в подлинности и качестве, без переплат.'
    },
    {
        title: 'Сотрудничество с лидерами',
        subtitle: 'Мы предлагаем вам лучшие цены без скрытых комиссий и неожиданных расходов. У нас вы всегда знаете, за что платите, и можете быть уверены, что получаете отличное соотношение цены и качества. Прозрачность и честность — основа наших предложений, и это делает покупки у нас выгодным и надежным выбором.'
    },
    {
        title: 'Простота и удобство',
        subtitle: 'Мы упростили процесс покупки для вашего удобства: от выбора товара до оформления заказа — всё быстро и интуитивно. Легкий интерфейс, удобная оплата и быстрая доставка!'
    }
]