import { FC } from "react";

interface IAbout { }

const About: FC<IAbout> = ({ }) => {

    return (
        <>
            <h1 className="text-red">About</h1>
        </>
    )
}

export default About