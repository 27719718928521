export const faqList = [
    {
        question: 'Что такое аппаратный кошелек OneKey и как он работает?',
        answer: 'Аппаратный кошелек OneKey — это устройство для безопасного хранения ваших криптовалют. Он сохраняет ваши приватные ключи в оффлайн-режиме, защищая их от хакеров и киберугроз. Когда вы хотите совершить транзакцию, кошелек подписывает ее локально и передает данные в сеть блокчейна, что обеспечивает максимальную безопасность ваших активов.'
    },
    {
        question: 'Зачем мне нужен аппаратный кошелек?',
        answer: 'Аппаратный кошелек нужен для защиты ваших криптоактивов от хакерских атак и киберугроз. Он хранит приватные ключи в оффлайн-режиме, что делает ваши средства недоступными для злоумышленников даже при взломе вашего компьютера или телефона. Это одно из самых безопасных решений для долгосрочного хранения криптовалют.'
    },
    {
        question: 'Предоставляете ли вы официальную гарантию на продукцию OneKey?',
        answer: 'Да, мы являемся официальными реселлерами OneKey и предоставляем официальную гарантию на все наши устройства. Вы можете быть уверены в подлинности и качестве продукта, а также получить полную поддержку в случае возникновения проблем с устройством.'
    },
    {
        question: 'Как происходит процесс доставки и сколько времени он занимает?',
        answer: 'Мы предлагаем различные варианты доставки в зависимости от вашего местоположения. В среднем доставка по стране занимает от 3 до 7 рабочих дней, а международные заказы могут занимать до 14 дней. Вы сможете отслеживать статус вашего заказа через наш сайт.'
    },
    {
        question: 'Какие способы оплаты вы принимаете?',
        answer: 'Мы принимаем широкий спектр способов оплаты, включая банковские карты (Visa, MasterCard) и электронные кошельки. Выбирайте наиболее удобный для вас вариант при оформлении заказа.'
    },
    {
        question: 'Предоставляете ли вы поддержку после покупки?',
        answer: 'Да, мы предлагаем постоянную поддержку после покупки. Если у вас возникнут вопросы по использованию устройства, настройке или любой другой проблеме, наша команда специалистов всегда готова помочь. Вы можете обратиться в нашу службу поддержки или написать в telegram.'
    },
    {
        question: 'Могу ли я использовать кошелек OneKey для работы с разными криптовалютами?',
        answer: 'Да, аппаратный кошелек OneKey поддерживает множество криптовалют, включая Bitcoin, Ethereum и другие токены на различных блокчейнах. Вы сможете управлять своими активами в одном месте с максимальной безопасностью.'
    }
]